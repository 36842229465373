<template>
    <div>
        <SenderIDTable :parent="parent" />
    </div>
</template>

<script>

import SenderIDTable from './senderidTable.vue'
import appConfig from '@/app.config'

export default {
    page: {
        title: 'Pending user-verification sender IDs',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    name: 'pending-user-approval',
    components: {
        SenderIDTable
    },
    data() {
        return {
            parent: {
                name: 'pendingUserApproval',
                storeDataName: 'holdingSenderIDs',
                from: 'hold-on',
                status: 'On_Hold'
            },
        }
    },
    methods: {

    },
    computed: {

    },
    mounted() {

    }
}
</script>

<style>

</style>